import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSignIn: false,
  id: null,
  name: "",
  phone: "",
  email: "",
};

const thisSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    updateAccount: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearAccount: (state, action) => {
      return initialState;
    },
  },
});

export const { updateAccount, clearAccount } = thisSlice.actions;
export default thisSlice.reducer;
