import { Box } from "@mui/material";
import React from "react";

const StatusOrder = ({ title, Icon, backgroundColor }) => {
  return (
    <Box
      component="span"
      sx={(theme) => ({
        display: "flex",
        width: "fit-content",
        alignItems: "center",
        backgroundColor,
        borderRadius: "0.25rem",
        color: "#fff",
        px: 1,
        py: 0.4,
        fontWeight: "bold",
        fontSize: 12,
        textAlign: "center",
      })}
    >
      <Icon sx={{ fontSize: 17, mr: 1 }} />
      {title}
    </Box>
  );
};

export default StatusOrder;
