import { createSlice } from "@reduxjs/toolkit";

const thisSlice = createSlice({
  name: "listOrderNew",
  initialState: [],
  reducers: {
    updateListOrderNew: (state, action) => {
      return action.payload;
    },
    updateItemInListOrderNew: (state, action) => {
      const { order_id, data_update } = action.payload;
      const newList = state.map((el) =>
        el.id === parseInt(order_id) ? { ...el, ...data_update } : el
      );
      return newList;
    },
    pushBeforeListOrderNew: (state, action) => {
      return [action.payload, ...state];
    },
    removeItemInListOrderNew: (state, action) => {
      const { unique_order_id } = action.payload;
      const newListOrder = state.filter(
        (el) => el.unique_order_id !== unique_order_id
      );
      return newListOrder;
    },
  },
});

export const {
  updateListOrderNew,
  updateItemInListOrderNew,
  pushBeforeListOrderNew,
  removeItemInListOrderNew,
} = thisSlice.actions;
export default thisSlice.reducer;
