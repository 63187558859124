import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeModalListTaxiWorking } from "../../../redux/slice/globalModalListTaxiWorking";
import { formatPhone } from "../../../functions/baseFunction";
import axios from "axios";
import { apis } from "../../../constants";
import { refreshToken } from "../../../token";
import { openGlobalAlert } from "../../../redux/slice/globalAlert";
import CachedIcon from "@mui/icons-material/Cached";
import { fetchListTaxiWorking } from "../../../init_data";
import { updateListTaxiWorking } from "../../../redux/slice/listTaxiWorking";

const GlobalModalListTaxiWorking = () => {
  const dispatch = useDispatch();

  const { isOpen, order_id } = useSelector(
    (state) => state.globalModalListTaxiWorking
  );
  const listTaxiWorkingRedux = useSelector((state) => state.listTaxiWorking);

  const [selectedTaxi, setSelectedTaxi] = useState(null);

  const [isLoadingData, setLoadingData] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (isOpen) {
        handleLoadingData();
      }
    }
    fetchData();
  }, [isOpen]);

  const handleClose = () => {
    setSelectedTaxi(null);
    dispatch(closeModalListTaxiWorking());
  };

  const fetchPointTaxi = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      await axios.put(
        apis.apiDashboard + `/point-taxi/${order_id}`,
        { delivery_id: selectedTaxi },
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      dispatch(
        openGlobalAlert({
          severity: "success",
          message: "Chỉ định thành công",
        })
      );
      handleClose();
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await fetchPointTaxi();
      } else if (error.response?.status === 400) {
        dispatch(
          openGlobalAlert({
            severity: "error",
            message: error.response.data,
          })
        );
      }
    }
  };

  const handleLoadingData = () => {
    setLoadingData(true);
    setTimeout(async () => {
      setSelectedTaxi(null);
      await fetchListTaxiWorking(dispatch);
      setLoadingData(false);
    }, 500);
  };

  const OneElement = ({ data }) => {
    return (
      <>
        <Grid item xs={6} sm={5} md={5}>
          <Typography
            sx={{
              fontSize: {
                xs: 13,
                sm: 14,
                md: 15,
                lg: 15,
                xl: 15,
              },
            }}
          >
            {data.code} - {data.user.name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          display={{
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          }}
        >
          {data.user.phone && (
            <Typography>{formatPhone(data.user.phone)}</Typography>
          )}
        </Grid>

        <Grid
          item
          xs={2}
          display={{
            xs: "none",
            sm: "block",
            md: "block",
            lg: "block",
            xl: "block",
          }}
        >
          {data.order_deliveries && (
            <Typography>{data.order_deliveries.length} đơn</Typography>
          )}
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          {data.is_run !== undefined && (
            <Box
              component="span"
              sx={(theme) => ({
                display: "flex",
                width: "fit-content",
                backgroundColor: data.is_run
                  ? theme.palette.error.main
                  : theme.palette.success.main,
                borderRadius: "0.25rem",
                color: "#fff",
                px: 1,
                py: 0.4,
                fontWeight: "bold",
                fontSize: {
                  xs: 9,
                  sm: 11,
                  md: 11,
                  lg: 11,
                  xl: 11,
                },
                textAlign: "center",
              })}
            >
              {data.is_run ? "Đang chạy" : "Chưa có"}
            </Box>
          )}
        </Grid>
        {order_id && (
          <Grid
            item
            xs={2}
            md={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              sx={{ fontSize: 10 }}
              variant={selectedTaxi === data.user_id ? "contained" : "outlined"}
              size="small"
              disabled={data.is_run === 1}
              onClick={() => setSelectedTaxi(data.user_id)}
            >
              Chọn
            </Button>
          </Grid>
        )}
      </>
    );
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Danh sách taxi đang hoạt động</Typography>
          {isLoadingData ? (
            <CircularProgress size={40} />
          ) : (
            <IconButton onClick={handleLoadingData}>
              <CachedIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1.5} rowSpacing={2.5}>
          {[
            { user_id: -1, code: "ALL", user: { name: "Tất cả tài" } },
            ...listTaxiWorkingRedux,
          ].map((el, index) => (
            <OneElement key={index} data={el} />
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
          size="small"
        >
          Đóng
        </Button>
        {order_id && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={selectedTaxi ? false : true}
            onClick={fetchPointTaxi}
          >
            Chỉ định
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GlobalModalListTaxiWorking;
