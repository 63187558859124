import PendingActionsIcon from "@mui/icons-material/PendingActions";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";

const listStatusOrder = [
  {},
  { id: 1, title: "Chờ xử lý", Icon: PendingActionsIcon, color: "#9D27B0" },
  { id: 2, title: "Đang chạy", Icon: MinorCrashIcon, color: "#1876D2" },
  { id: 3, title: "Hoàn thành", Icon: PendingActionsIcon, color: "#FF5722" },
  { id: 4, title: "Đã huỷ", Icon: PendingActionsIcon, color: "#C62828" },
];

const exportedObject = {
  listStatusOrder,
};

export default exportedObject;
