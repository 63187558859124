import { createSlice } from "@reduxjs/toolkit";

const thisSlice = createSlice({
  name: "menu",
  initialState: {
    isOpen: false,
  },
  reducers: {
    openMenu: (state, action) => {
      return { isOpen: true };
    },
    closeMenu: (state, action) => {
      return { isOpen: false };
    },
  },
});

export const { openMenu, closeMenu } = thisSlice.actions;
export default thisSlice.reducer;
