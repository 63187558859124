import { createSlice } from "@reduxjs/toolkit";

const thisSlice = createSlice({
  name: "globalAlert",
  initialState: {
    isOpen: false,
    severity: "success", // error, warning, info, success
    message: "",
  },
  reducers: {
    openGlobalAlert: (state, action) => {
      return { ...action.payload, isOpen: true };
    },
    closeGlobalAlert: (state, action) => {
      return { ...state, isOpen: false };
    },
  },
});

export const { openGlobalAlert, closeGlobalAlert } = thisSlice.actions;
export default thisSlice.reducer;
