import React from "react";

import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeGlobalAlert } from "../../../redux/slice/globalAlert";

const GlobalAlert = () => {
  const dispatch = useDispatch();
  const globalAlertRedux = useSelector((state) => state.globalAlert);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeGlobalAlert());
  };

  return (
    <Snackbar
      open={globalAlertRedux.isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={globalAlertRedux.severity}
        sx={{ width: "100%" }}
      >
        {globalAlertRedux.message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalAlert;
