function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const COLORS = {
  // base colors
  primary: "#FC6D3F", // orange
  secondary: "#03DAC6", // gray
  mainBackground: "#26d953",
  mainComponent: "#208000",

  lightGray: "#F5F5F6",
  lightGray2: "#F6F6F7",
  lightGray3: "#EFEFF1",
  lightGray4: "#F8F8F9",
  transparent: "transparent",
  darkgray: "#898C95",

  white: "#ffffff",
  grey1: "#f4f4f4",
  grey2: "#e9ecef",
  grey3: "#adb5bd",
  grey4: "#999999",
  grey5: "#7e8387",
  grey6: "#777777",
  grey7: "#707070",
  grey8: "#383838",
  grey9: "#121212",
  grey10: "#dee2e6",
  grey11: "#1e1e1e",
  grey12: "#555555",
  black: "#000000",

  red: "#e4002b",
  redTwo: "#e60023",
  orange: "#f2711c",
  yellow: "#fbbd08",
  olive: "#B5CC18",
  green: "#00ba51",
  greenBlue: "#20c997",
  teal: "#00B5AD",
  blue: "#2154ba",
  violet: "#6435c9",
  purple: "#A333C8",
  pink: "#E03997",
  brown: "#A5673F",

  main_color_service_hotel: '#FF3266',
};

export const SIZES = {
  width: getWindowDimensions().width,
  height: getWindowDimensions().height,
};

const appTheme = { COLORS, SIZES };

export default appTheme;
