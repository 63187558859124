import account from "./account";
import globalAlert from "./globalAlert";
import globalLoading from "./globalLoading";
import customizationMenu from "./customizationMenu";
import listOrderNew from "./listOrderNew";
import globalModalDetailOrder from "./globalModalDetailOrder";
import menu from "./menu";
import searchListOrder from "./searchListOrder";
import globalModalConfirm from "./globalModalConfirm";
import listTaxiWorking from "./listTaxiWorking";
import globalModalListTaxiWorking from "./globalModalListTaxiWorking";

const rootSlice = {
  account,
  globalAlert,
  globalLoading,
  customizationMenu,
  listOrderNew,
  globalModalDetailOrder,
  menu,
  searchListOrder,
  globalModalConfirm,
  listTaxiWorking,
  globalModalListTaxiWorking
};

export default rootSlice;
