import { format } from "date-fns";
import React from "react";

const formatPhone = (phone) => {
  return phone ? phone.replace("+84", "0") : "";
};

function formatPrice(price) {
  if (price === 0 || price === "0") {
    return "0";
  }

  if (!price) {
    return "";
  }

  let formated = "";

  const isDecimal =
    String(price).substring(price.length - 3, price.length) === ".00";

  if (isDecimal) {
    formated = String(price).substring(0, price.length - 3);
  } else {
    formated = String(price).substring(0, price.length);
  }

  formated = formated.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

  return formated;
}

const renderTextWithLineBreaks = (text) => {
  if (!text || text === "null") {
    return "";
  }

  const lines = text.split("\n");
  return lines.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

function formatDateTime(date, formatString) {
  const formattedDate = format(new Date(date), formatString);
  return formattedDate;
}

export { formatPhone, formatPrice, renderTextWithLineBreaks, formatDateTime };
