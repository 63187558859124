import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COLORS, apis } from "../constants";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { refreshToken } from "../token";
import { useNavigate } from "react-router-dom";
import { clearAccount } from "../redux/slice/account";
import MenuIcon from "@mui/icons-material/Menu";
import { openMenu } from "../redux/slice/menu";
import Logout from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
import { openGlobalAlert } from "../redux/slice/globalAlert";
import { deepOrange } from "@mui/material/colors";
import { openModalListTaxiWorking } from "../redux/slice/globalModalListTaxiWorking";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";

const OneText = ({ text }) => {
  return (
    <Typography
      sx={{
        fontWeight: "bold",
        color: COLORS.white,
        fontSize: {
          xs: 11,
          sm: 12,
          md: 13,
          lg: 14,
          xl: 14,
        },
      }}
    >
      {text}
    </Typography>
  );
};

const DialogFormChangePasswork = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const accountRedux = useSelector((state) => state.account);

  const [pwCurrent, setPwCurrent] = useState("");
  const [pwNew, setPwNew] = useState("");
  const [cpwNew, setCpwNew] = useState("");
  const [errors, setErrors] = useState({});

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const fetchChangePassword = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.put(
        apis.apiDashboard + `/change-password/${accountRedux.id}`,
        { pwCurrent, pwNew, cpwNew },
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      setErrors({});
      setPwCurrent("");
      setPwNew("");
      setCpwNew("");

      dispatch(
        openGlobalAlert({
          severity: "success",
          message: response.data,
        })
      );

      handleCloseDialog();
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await fetchChangePassword();
      } else if (error.response?.status === 400) {
        const { type, message, errors } = error.response.data;
        if (type === "validate") {
          setErrors(errors);
        } else {
          dispatch(
            openGlobalAlert({
              severity: "error",
              message,
            })
          );
        }
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Đổi mật khẩu</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Mật khẩu hiện tại"
          type="password"
          fullWidth
          variant="standard"
          value={pwCurrent}
          onChange={(e) => setPwCurrent(e.target.value)}
          error={!!errors.pwCurrent}
          helperText={errors.pwCurrent}
        />
        <TextField
          margin="dense"
          label="Mật khẩu mới"
          type="password"
          fullWidth
          variant="standard"
          value={pwNew}
          onChange={(e) => setPwNew(e.target.value)}
          error={!!errors.pwNew}
          helperText={errors.pwNew}
        />
        <TextField
          margin="dense"
          label="Nhập lại mật khẩu mới"
          type="password"
          fullWidth
          variant="standard"
          value={cpwNew}
          onChange={(e) => setCpwNew(e.target.value)}
          error={!!errors.cpwNew}
          helperText={errors.cpwNew}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Đóng</Button>
        <Button onClick={fetchChangePassword}>Xác nhận đổi</Button>
      </DialogActions>
    </Dialog>
  );
};

const MenuAction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accountRedux = useSelector((state) => state.account);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const [openDialogChangePw, setOpenDialogChangePw] = useState(false);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getCharAvatar = () => {
    const arr = accountRedux.name.split(" ");
    return arr[arr.length - 1].charAt(0);
  };

  const signOut = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      await axios.post(
        apis.apiDashboard + "/sign-out",
        { admin_id: accountRedux.id },
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );
      localStorage.removeItem("@loginData");
      dispatch(clearAccount(false));
      navigate("/sign-in", { replace: true });
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await signOut();
      }
    }
  };

  return (
    <div>
      <Tooltip title="Tuỳ chọn">
        <IconButton onClick={handleClickMenu} size="small" sx={{ ml: 1 }}>
          <Avatar sx={{ width: 32, height: 32, bgcolor: deepOrange[500] }}>
            {getCharAvatar()}
          </Avatar>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => dispatch(openModalListTaxiWorking({ order_id: null }))}
        >
          <ListItemIcon>
            <LocalTaxiIcon fontSize="small" />
          </ListItemIcon>
          Danh sách tài
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setOpenDialogChangePw(true)}>
          <ListItemIcon>
            <LockIcon fontSize="small" />
          </ListItemIcon>
          Đổi mật khẩu
        </MenuItem>

        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Đăng xuất
        </MenuItem>
      </Menu>
      <DialogFormChangePasswork
        open={openDialogChangePw}
        setOpen={setOpenDialogChangePw}
      />
    </div>
  );
};

const TheHeader = () => {
  const dispatch = useDispatch();
  const accountRedux = useSelector((state) => state.account);

  const navigate = useNavigate();

  function renderListTitleNavigation() {
    const OneElement = ({ title, path }) => (
      <Button
        sx={{
          color: COLORS.white,
          fontWeight: "bold",
          mr: 1,
          fontSize: {
            md: 14,
            lg: 16,
            xl: 16,
          },
        }}
        onClick={() => navigate(path)}
      >
        {title}
      </Button>
    );
    return (
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
          flexDirection: "row",
          ml: 3,
        }}
      >
        <OneElement title={"Đơn mới"} path={"/home"} />
        <OneElement title={"Tra cứu đơn"} path={"/search"} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        py: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <IconButton
          sx={{
            p: 0,
            mr: 2,
            display: {
              xs: "block",
              sm: "block",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
          size="large"
          onClick={() => dispatch(openMenu())}
        >
          <MenuIcon sx={{ color: COLORS.white }} />
        </IconButton>

        <Typography
          sx={{
            fontFamily: "Pacifico",
            color: COLORS.white,
            fontSize: {
              xs: 14,
              sm: 17,
              md: 20,
              lg: 27,
              xl: 27,
            },
          }}
        >
          IQI TAXI HELLO
        </Typography>
        {renderListTitleNavigation()}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Chip
          label="Danh sách tài"
          variant="outlined"
          size="small"
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            color: COLORS.white,
            mr: 1.5,
          }}
          onClick={() => dispatch(openModalListTaxiWorking({ order_id: null }))}
        />
        <Box>
          <OneText text={accountRedux.name} />
          <OneText text={accountRedux.email} />
        </Box>
        <MenuAction />
      </Box>
    </Box>
  );
};

export default React.memo(TheHeader);
