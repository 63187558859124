import soundTingTing from "../assets/sound/notification.mp3";
import soundNotifyNewOrder from "../assets/sound/notification_up.mp3";

const soundPlayTingTing = () => {
  new Audio(soundTingTing).play();
};

const soundPlayNewOrder = () => {
  new Audio(soundNotifyNewOrder).play();
};

export { soundPlayTingTing, soundPlayNewOrder };
