import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/vi";
import { MaterialReactTable } from "material-react-table";
import { formatPhone, formatPrice } from "../../functions/baseFunction";
import { useDispatch, useSelector } from "react-redux";
import { SIZES, apis, variable } from "../../constants";
import { MRT_Localization_VI } from "material-react-table/locales/vi";
import { showModalDetailOrder } from "../../redux/slice/globalModalDetailOrder";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import { refreshToken } from "../../token";
import { openGlobalAlert } from "../../redux/slice/globalAlert";
import {
  closeGlobalLoading,
  openGlobalLoading,
} from "../../redux/slice/globalLoading";
import { updateSearchListOrder } from "../../redux/slice/searchListOrder";
import dayjs from "dayjs";
import getDataByStatusOrder from "../../functions/statusOrder";
import { StatusOrder } from "../util/component";

const PageSearch = () => {
  const dispatch = useDispatch();

  const { startDate, endDate, listOrder } = useSelector(
    (state) => state.searchListOrder
  );

  const [startDateCurrent, setStartDateCurrent] = useState(
    startDate ? dayjs(startDate) : null
  );
  const [endDateCurrent, setEndDateCurrent] = useState(
    endDate ? dayjs(endDate) : null
  );

  // const [value, setValue] = useState(dayjs("2022-04-17"));

  useEffect(() => {
    async function fetchData() {
      if (startDateCurrent && endDateCurrent) {
        handleLoadOrder();
      }
    }
    fetchData();
  }, []);

  const handleLoadOrder = () => {
    if (!startDateCurrent || !endDateCurrent) {
      return dispatch(
        openGlobalAlert({
          severity: "error",
          message: "Bạn vui lòng đủ ngày bắt đầu và ngày kết thúc",
        })
      );
    }

    dispatch(openGlobalLoading());
    setTimeout(() => {
      fetchSearchOrders();
      dispatch(closeGlobalLoading());
    }, 1000);
  };

  const fetchSearchOrders = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    if (userLocal !== null) {
      try {
        const response = await axios.post(
          apis.apiDashboard + "/search-order-by-range-date",
          { startDate: startDateCurrent, endDate: endDateCurrent },
          {
            headers: {
              verify: userLocal.accessToken,
            },
          }
        );

        const { startDate, endDate, listOrder } = response.data;

        dispatch(updateSearchListOrder({ startDate, endDate, listOrder }));
      } catch (error) {
        if (error.response?.status === 401) {
          await refreshToken(userLocal);
          await fetchSearchOrders();
        }
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at",
        header: "Thời gian đặt",
        size: 100,
        enableClickToCopy: true,
      },
      {
        accessorKey: "name_user", //access nested data with dot notation
        header: "Tên liên hệ",
        size: 120,
        enableClickToCopy: true,
      },
      {
        accessorKey: "phone",
        header: "SĐT liên hệ",
        size: 100,
        enableClickToCopy: true,
        Cell: ({ renderedCellValue }) => `${formatPhone(renderedCellValue)}`,
      },
      {
        accessorKey: "address",
        header: "Địa chỉ",
        size: 200,
        enableClickToCopy: true,
      },
      {
        accessorKey: "total",
        header: "Giá xe tạm tính",
        size: 100,
        enableClickToCopy: true,
        Cell: ({ renderedCellValue }) => `${formatPrice(renderedCellValue)}đ`,
      },
      {
        accessorFn: (row) => {
          return {
            orderstatus_id: row.orderstatus_id,
            point_delivery_id: row.point_delivery_id,
          };
        },
        header: "Trạng thái",
        size: 100,
        enableClickToCopy: true,
        Cell: ({ renderedCellValue }) => {
          const { orderstatus_id, point_delivery_id } = renderedCellValue;

          const { titleStatus, backgroundColor, Icon } = getDataByStatusOrder({
            orderstatus_id,
            point_delivery_id,
          });

          return (
            <StatusOrder title={titleStatus} Icon={Icon} backgroundColor={backgroundColor} />
          );
        },
      },
    ],
    []
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="vi">
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            },
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: {
                xs: 16,
                sm: 16,
                md: 18,
                lg: 24,
                xl: 24,
              },
            }}
          >
            Tra cứu đơn
          </Typography>
          <Box
            sx={{
              display: "flex:",
              alignItems: "center",
              mb: 1,
              mt: 1,
            }}
          >
            <DatePicker
              label="Ngày bắt đầu"
              value={startDateCurrent}
              onChange={(newValue) => setStartDateCurrent(newValue)}
              sx={{ mr: 2, mb: 1 }}
              slotProps={{ textField: { size: "small" } }}
            />
            <DatePicker
              label="Ngày kết thúc"
              value={endDateCurrent}
              onChange={(newValue) => setEndDateCurrent(newValue)}
              sx={{ mr: 2 }}
              slotProps={{ textField: { size: "small" } }}
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              sx={{ mb: 0, alignSelf: "center" }}
              onClick={() => handleLoadOrder()}
            >
              Xem
            </Button>
          </Box>
        </Box>

        <MaterialReactTable
          columns={columns}
          data={listOrder}
          //   enableRowSelection //enable some features
          //   enableColumnOrdering
          enableGlobalFilter={true} //turn off a feature
          // custom tìm kiếm
          enableFilterMatchHighlighting={false}
          muiSearchTextFieldProps={{
            placeholder: "Tìm kiếm",
          }}
          // show/hide dấu 3 chấm ở tiêu đề mỗi cột
          enableColumnActions={false}
          // show/hide nút tuỳ chỉnh kích cỡ bảng
          enableDensityToggle={true}
          // show/hide nút fullscreen
          enableFullScreenToggle={true}
          // show/hide dàn nút ở trên
          enableTopToolbar={true}
          // resize column
          enableColumnResizing
          columnResizeMode="onChange"
          // đánh số dòng
          enableRowNumbers
          rowNumberMode="original"
          // max height bảng
          muiTableContainerProps={{
            sx: {
              height: SIZES.height * 0.56, // K1
            },
          }}
          // ngôn ngữ hoá
          localization={MRT_Localization_VI}
          // gắn cứng header
          enableStickyHeader
          // custom page
          muiTablePaginationProps={{
            labelRowsPerPage: "Số dòng mỗi trang",
            labelDisplayedRows: ({ from, to, count, page }) => {
              return `Trang ${page + 1}`;
            },
          }}
          enableRowActions
          positionActionsColumn="last"
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
              <IconButton
                color="primary"
                onClick={() => {
                  dispatch(showModalDetailOrder({ data: row.original }));
                }}
              >
                <RemoveRedEyeIcon />
              </IconButton>
            </Box>
          )}
        />
      </>
    </LocalizationProvider>
  );
};

export default PageSearch;
