import { Badge, Box, Chip, Tooltip, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";

// import soundReng from "src/assets/sound/reng.mp3";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -15,
    top: 0,
  },
}));

const MyTimer = ({
  initialMinute = 0,
  initialSeconds = 0,
  delivery_code,
  delivery_name,
}) => {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [color, setColor] = useState("success");

  const soundPlay = () => {
    // new Audio(soundReng).play();
  };

  const checkTimeForChangeColor = () => {
    if (minutes > 1 || (minutes === 1 && seconds >= 30)) {
      setColor("#D3302F");
    } else {
      setColor("#2F7D31");
    }
  };

  useEffect(() => {
    checkTimeForChangeColor();
    let myInterval = setInterval(() => {
      if (seconds >= 0) {
        const newSeconds = seconds + 1;
        setSeconds(newSeconds);

        checkTimeForChangeColor();
        if (minutes === 1 && newSeconds === 30) {
          soundPlay();
        }
      }

      if (seconds === 59) {
        // if (minutes === 4) {
        //   clearInterval(myInterval);
        // } else {
        const newMinutes = minutes + 1;
        setMinutes(newMinutes);
        setSeconds(0);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Box sx={{ display: "flex", mb: 0.5, justifyContent: "center" }}>
      <Box
        component="span"
        sx={(theme) => ({
          display: "flex",
          width: "fit-content",
          alignItems: "center",
          borderRadius: "0.25rem",
          bgcolor: color,
          color: "#fff",
          // maxWidth: "13ch",
          px: 1,
          py: 0.4,
          fontWeight: "bold",
          fontSize: 12,
          textAlign: "center",
        })}
      >
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </Box>

      {delivery_code && (
        <Tooltip title={delivery_name}>
          <Chip
            label={delivery_code}
            color="secondary"
            size="small"
            sx={{ fontSize: 12, ml: 1 }}
          />
        </Tooltip>
      )}
    </Box>
  );

  return (
    <StyledBadge
      badgeContent={delivery_code ? delivery_code : null}
      color={color}
      sx={{ display: "flex" }}
    >
      <Typography>
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </Typography>
    </StyledBadge>
  );
};

export default MyTimer;
