import axios from "axios";
import { apis } from "../constants";
import { refreshToken } from "../token";
import { openGlobalAlert } from "../redux/slice/globalAlert";

const fetchRecallOrder = async (order_id, dispatch) => {
  const userLocal = JSON.parse(localStorage.getItem("@loginData"));
  try {
    const response = await axios.put(
      apis.apiDashboard + `/recall-order/${order_id}`,
      {},
      {
        headers: {
          verify: userLocal.accessToken,
        },
      }
    );

    dispatch(
      openGlobalAlert({
        severity: "success",
        message: response.data,
      })
    );
  } catch (error) {
    if (error.response?.status === 401) {
      await refreshToken(userLocal);
      await fetchRecallOrder(order_id, dispatch);
    } else if (error.response?.status === 400) {
      dispatch(
        openGlobalAlert({
          severity: "error",
          message: error.response.data,
        })
      );
    }
  }
};
export { fetchRecallOrder };
