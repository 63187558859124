import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import PrivateLayout from "../layout";

const PrivateRoute = ({ Component, ...rest }) => {
  const accountRedux = useSelector((state) => state.account);

  return accountRedux.isSignIn ? (
    <PrivateLayout Childrent={() => <Outlet />} />
  ) : (
    <Navigate to="/sign-in" />
  );
};

export default PrivateRoute;
