import React from "react";
import { COLORS, SIZES } from "../constants";
import TheHeader from "./TheHeader";
import GlobalModalDetailOrder from "../pages/util/global/GlobalModalDetailOrder";
import { Box } from "@mui/material";
import TheMenu from "./TheMenu";

const ComponentGlobalPrivate = () => {
  return (
    <>
      <GlobalModalDetailOrder />
    </>
  );
};

const PrivateLayout = ({ Childrent }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: SIZES.height,
        backgroundColor: "#3366FF",
        px: {
          xs: 2,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
        },
      }}
    >
      <TheHeader />
      <TheMenu />
      <Box
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: COLORS.white,
          borderRadius: "10px",
          boxShadow: "0 0 5px",
          px: {
            xs: 2,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
          },
          py: {
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 3,
          },
          mb: 4,
        }}
      >
        <Box
          sx={{
            flex: "1 1 auto",
            width: "100%",
            height: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Childrent />
        </Box>
      </Box>
      <ComponentGlobalPrivate />
    </Box>
  );
};

export default PrivateLayout;
