import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import { variable } from "../constants";

const getDataByStatusOrder = ({
  orderstatus_id,
  point_delivery_id,
  created_at,
  updated_at,
  delivery,
  order_delivery,
}) => {
  let titleStatus = "";
  let backgroundColor = null;
  let Icon = null;
  let badgeContentTimer = null;
  let time_handle = null;
  let delivery_name = "";

  if (orderstatus_id === 1 && point_delivery_id) {
    titleStatus = "Chờ tài nhận";
    backgroundColor = "#2F7D31";
    Icon = DepartureBoardIcon;
    time_handle = updated_at;
    badgeContentTimer = delivery ? delivery?.code : "ALL";
    delivery_name = delivery?.user?.name;
  } else {
    const statusCurrent = variable.listStatusOrder[orderstatus_id];
    backgroundColor = statusCurrent.color;
    titleStatus = statusCurrent.title;
    Icon = statusCurrent.Icon;
    badgeContentTimer = order_delivery?.delivery?.code;
    delivery_name = order_delivery?.delivery?.user?.name;
    if (statusCurrent.id === 1) {
      time_handle = created_at;
    } else if (statusCurrent.id === 2) {
      time_handle = order_delivery?.created_at;
    }
  }

  return {
    titleStatus,
    backgroundColor,
    Icon,
    badgeContentTimer,
    time_handle,
    delivery_name,
  };
};

export default getDataByStatusOrder;
