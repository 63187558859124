import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModalConfirm } from "../../../redux/slice/globalModalConfirm";
import { soundPlayTingTing } from "../../../functions/sound";
import { fetchRecallOrder } from "../../../functions/modalConfirm";

export const MODAL_CONFIRM_KEY_WELLCOM = "WELLCOM";
export const MODAL_CONFIRM_KEY_RECALL_ORDER = "RECALL_ORDER";

const GlobalModalConfirm = () => {
  const dispatch = useDispatch();
  const {
    visible,
    title,
    content,
    isShowButtonCancel,
    isShowButtonAccept,
    titleCancel,
    titleAccept,
    keyActionAccept,
    data,
  } = useSelector((state) => state.globalModalConfirm);

  const handleClose = () => {
    dispatch(closeModalConfirm());
  };

  const handleAccept = () => {
    if (keyActionAccept === MODAL_CONFIRM_KEY_WELLCOM) {
      soundPlayTingTing();
    } else if (keyActionAccept === MODAL_CONFIRM_KEY_RECALL_ORDER) {
      fetchRecallOrder(data?.order_id, dispatch);
    }
    handleClose();
  };

  return (
    <Dialog
      open={visible}
      // onClose={handleClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title ? title : "Thông báo"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isShowButtonCancel && (
          <Button onClick={handleClose}>
            {titleCancel ? titleCancel : "Đóng"}
          </Button>
        )}
        {isShowButtonAccept && (
          <Button onClick={handleAccept}>
            {titleAccept ? titleAccept : "OK"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GlobalModalConfirm;
