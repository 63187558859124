import { createSlice } from "@reduxjs/toolkit";

const thisSlice = createSlice({
  name: "globalModalListTaxiWorking",
  initialState: {
    isOpen: false,
    order_id: null,
  },
  reducers: {
    openModalListTaxiWorking: (state, action) => {
      return { isOpen: true, ...action.payload };
    },
    closeModalListTaxiWorking: (state, action) => {
      return { ...state, isOpen: false };
    },
  },
});

export const { openModalListTaxiWorking, closeModalListTaxiWorking } = thisSlice.actions;
export default thisSlice.reducer;
