import { createSlice } from "@reduxjs/toolkit";

const thisSlice = createSlice({
  name: "globalModalConfirm",
  initialState: {
    visible: false,
    title: "",
    content: "",
    isShowButtonCancel: false,
    isShowButtonAccept: false,
    titleCancel: "",
    titleAccept: "",
    keyActionAccept: "",
    data: null // chứa data đi kèm
  },
  reducers: {
    showModalConfirm: (state, action) => {
      return { ...action.payload, visible: true };
    },
    closeModalConfirm: (state, action) => {
      return { ...state, visible: false };
    },
  },
});

export const { showModalConfirm, closeModalConfirm } = thisSlice.actions;
export default thisSlice.reducer;
