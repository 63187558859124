import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import PageSignIn from "./pages/sign_in";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SIZES, apis } from "./constants";
import { updateAccount } from "./redux/slice/account";
import { refreshToken } from "./token";
import GlobalLoading from "./pages/util/global/GlobalLoading";
import GlobalAlert from "./pages/util/global/GlobalAlert";
import { CircularProgress, Typography } from "@mui/material";
import NotFoundPage from "./pages/not_found";
import privateRoutes from "./routes";
import PrivateRoute from "./routes/PrivateRoute";
import {
  disconnectSocket,
  initiateSocket,
  socket,
} from "./socket/useWebSocket";
import { openGlobalAlert } from "./redux/slice/globalAlert";
import {
  pushBeforeListOrderNew,
  removeItemInListOrderNew,
  updateItemInListOrderNew,
} from "./redux/slice/listOrderNew";
import { soundPlayNewOrder } from "./functions/sound";
import GlobalModalConfirm, {
  MODAL_CONFIRM_KEY_WELLCOM,
} from "./pages/util/global/GlobalModalConfirm";
import { showModalConfirm } from "./redux/slice/globalModalConfirm";
import { initData } from "./init_data";
import GlobalModalListTaxiWorking from "./pages/util/global/GlobalModalListTaxiWorking";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const ComponentGlobal = () => (
  <>
    <GlobalLoading />
    <GlobalAlert />
    <GlobalModalConfirm />
    <GlobalModalListTaxiWorking />
  </>
);

function App() {
  const dispatch = useDispatch();
  const accountRedux = useSelector((state) => state.account);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setTimeout(async () => {
        await checkLogin();
        setLoading(false);
      }, 1000);
    }
    fetchData();

    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (accountRedux.isSignIn) {
        const statusInitData = await initData(dispatch);
        if (statusInitData === "OK") {
          dispatch(
            showModalConfirm({
              content: "Chúc bạn có một ngày làm việc vui vẻ !!",
              isShowButtonCancel: false,
              isShowButtonAccept: true,
              titleAccept: "Đóng",
              keyActionAccept: MODAL_CONFIRM_KEY_WELLCOM,
            })
          );
        }
      }
    }
    fetchData();
  }, [accountRedux.isSignIn]);

  useEffect(() => {
    async function fetchData() {
      if (socket) {
        await listenSocket();
      }
    }
    fetchData();
  }, [socket]);

  const listenSocket = () => {
    socket.on("newOrder", (data) => {
      if (data) {
        const { response } = data;
        soundPlayNewOrder();
        dispatch(
          openGlobalAlert({
            severity: "success",
            message: "Có đơn khách mới đặt",
          })
        );
        dispatch(pushBeforeListOrderNew(response));
      }
    });

    socket.on("pointOrder", (data) => {
      if (data) {
        dispatch(updateItemInListOrderNew(data));
      }
    });

    socket.on("recallOrder", (data) => {
      if (data) {
        dispatch(updateItemInListOrderNew(data));
      }
    });

    socket.on("receivedOrder", (data) => {
      if (data) {
        dispatch(updateItemInListOrderNew(data));
      }
    });

    socket.on("finishOrder", (data) => {
      if (data) {
        dispatch(
          removeItemInListOrderNew({ unique_order_id: data.unique_order_id })
        );
      }
    });

    socket.on("connect", () => {
      // Xử lý khi kết nối bị đứt
      socket.on("disconnect", () => {
        socket.close();
        const userLocal = JSON.parse(localStorage.getItem("@loginData"));
        initiateSocket(accountRedux.id, userLocal.accessToken);
      });

      // Xử lý khi kết nối lại thành công
      socket.on("reconnect", () => {

        // Các hành động sau khi kết nối lại thành công
        // Ví dụ: load lại dữ liệu, gửi lại các yêu cầu chưa thành công trước đó, vv.
      });
    });
  };

  // useEffect(() => {
  //   const handleFocus = () => {
  //     // Thực hiện việc thiết lập lại kết nối socket khi trình duyệt tập trung trở lại
  //     // ...

  //   };

  //   const handleBlur = () => {
  //     // Thực hiện việc đóng kết nối socket khi trình duyệt mất tập trung
  //     // ...
  //   };

  //   window.addEventListener('focus', handleFocus);
  //   window.addEventListener('blur', handleBlur);

  //   return () => {
  //     // Xóa bỏ các sự kiện khi component unmount
  //     window.removeEventListener('focus', handleFocus);
  //     window.removeEventListener('blur', handleBlur);
  //   };
  // }, []);

  const checkLogin = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    if (userLocal !== null) {
      try {
        const response = await axios.get(apis.apiDashboard + "/profile", {
          headers: {
            verify: userLocal.accessToken,
            role: 1,
          },
        });

        dispatch(updateAccount({ ...response.data, isSignIn: true }));
        initiateSocket(response.data.id, userLocal.accessToken);
      } catch (error) {
        if (error.response?.status === 401) {
          await refreshToken(userLocal);
          await checkLogin();
        }
      }
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          height: SIZES.height,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={80} />
        <Typography variant="h5" mt={4}>
          Đang tải dữ liệu
        </Typography>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <ComponentGlobal />
        <Routes>
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route
            exact
            path="/sign-in"
            element={
              accountRedux.isSignIn ? <Navigate to="/" /> : <PageSignIn />
            }
          />
          <Route exact path="/" element={<PrivateRoute />}>
            {privateRoutes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                element={<route.component />}
              />
            ))}
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
