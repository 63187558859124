import React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeModalDetailOrder } from "../../../redux/slice/globalModalDetailOrder";
import {
  formatPhone,
  formatPrice,
  renderTextWithLineBreaks,
} from "../../../functions/baseFunction";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { apis } from "../../../constants";
import { openGlobalAlert } from "../../../redux/slice/globalAlert";
import { refreshToken } from "../../../token";
import { StatusOrder } from "../component";
import getDataByStatusOrder from "../../../functions/statusOrder";

const GlobalModalDetailOrder = () => {
  const dispatch = useDispatch();
  const { visible, data } = useSelector(
    (state) => state.globalModalDetailOrder
  );

  const handleClose = () => {
    dispatch(closeModalDetailOrder());
  };

  const handleConfirnOrder = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.put(
        apis.apiDashboard + "/confirm-order",
        { order_id: data.id },
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      dispatch(
        openGlobalAlert({
          severity: "success",
          message: response.data,
        })
      );

      handleClose();
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await handleConfirnOrder();
      } else if (error.response?.status === 400) {
        dispatch(
          openGlobalAlert({
            severity: "error",
            message: error.response.data,
          })
        );
      }
    }
  };

  const OneInfo = ({ title, value }) => {
    return (
      <Box mb={3}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: {
              xs: 12,
              sm: 12,
              md: 13,
              lg: 15,
              xl: 15,
            },
          }}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: 13,
              sm: 13,
              md: 14,
              lg: 16,
              xl: 16,
            },
          }}
        >
          {value ? value : "Không có"}
        </Typography>
      </Box>
    );
  };

  const RenderStatusOrder = () => {
    const { titleStatus, backgroundColor, Icon } = getDataByStatusOrder({
      orderstatus_id: data.orderstatus_id,
      point_delivery_id: data.point_delivery_id,
    });

    return (
      <StatusOrder
        title={titleStatus}
        Icon={Icon}
        backgroundColor={backgroundColor}
      />
    );
  };

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle id="alert-dialog-title">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: 15,
                sm: 15,
                md: 18,
                lg: 22,
                xl: 22,
              },
            }}
          >
            Chi tiết đơn #{data.unique_order_id}
          </Typography>
          <RenderStatusOrder />
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <OneInfo title={"Thời gian đặt"} value={data.created_at} />
            <OneInfo title={"Tên liên hệ"} value={data.name_user} />
            <OneInfo title={"SĐT liên hệ"} value={formatPhone(data.phone)} />
            <OneInfo
              title={"Ghi chú"}
              value={data.note ? data.mote : "Không có"}
            />
            <OneInfo
              title={"Tài xế nhận"}
              value={
                data.order_delivery
                  ? `${data.order_delivery.delivery.code} - ${data.order_delivery.delivery.user.name}`
                  : "Chưa có"
              }
            />
          </Grid>
          <Grid item xs={6}>
            <OneInfo
              title={"Địa chỉ đón"}
              value={renderTextWithLineBreaks(
                `${data.address}${
                  data.latitude
                    ? `\nToạ độ: ${data.latitude}, ${data.longitude}`
                    : ""
                }`
              )}
            />
            <OneInfo
              title={"Địa chỉ chở đến"}
              value={renderTextWithLineBreaks(
                `${data.service_taxi?.address}${
                  data.service_taxi?.latitude
                    ? `\nToạ độ: ${data.service_taxi?.latitude}, ${data.service_taxi?.longitude}`
                    : ""
                }`
              )}
            />
            <OneInfo
              title={"Giá xe tạm tính"}
              value={formatPrice(data.total) + "đ"}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          size="small"
          color="error"
          startIcon={<CloseIcon />}
        >
          Đóng
        </Button>
        {/* {data.orderstatus_id === 1 && (
          <Button
            onClick={handleConfirnOrder}
            variant="contained"
            size="small"
            color="primary"
            startIcon={<DoneIcon />}
          >
            Xác nhận đơn
          </Button>
        )} */}
      </DialogActions>
    </Dialog>
  );
};

export default GlobalModalDetailOrder;
