import { createSlice } from "@reduxjs/toolkit";

const thisSlice = createSlice({
  name: "globalModalDetailOrder",
  initialState: {
    visible: false,
    data: {},
  },
  reducers: {
    showModalDetailOrder: (state, action) => {
      return { ...action.payload, visible: true };
    },
    closeModalDetailOrder: (state, action) => {
      return { ...state, visible: false };
    },
  },
});

export const { showModalDetailOrder, closeModalDetailOrder } =
  thisSlice.actions;
export default thisSlice.reducer;
