import React from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import InboxIcon from "@mui/icons-material/MoveToInbox";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { closeMenu } from "../redux/slice/menu";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../constants";
import MenuIcon from "@mui/icons-material/Menu";

const TheMenu = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isOpen } = useSelector((state) => state.menu);

  const handleCloseMenu = () => {
    dispatch(closeMenu());
  };

  const handleNavigate = (path) => {
    handleCloseMenu();
    setTimeout(() => {
      navigate(path);
    }, 500);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={handleCloseMenu}
      onKeyDown={handleCloseMenu}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          bgcolor: "#3366FF",
          px: 2,
          py: 2,
        }}
      >
        <Typography
          variant="h5"
          style={{
            fontFamily: "Pacifico",
            color: COLORS.white,
          }}
        >
          IQI TAXI HELLO
        </Typography>
        <IconButton size="medium" onClick={() => dispatch(closeMenu())}>
          <MenuIcon sx={{ color: COLORS.white }} />
        </IconButton>
      </Box>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleNavigate("/home")}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary={"Đơn mới"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleNavigate("/search")}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary={"Tra cứu đơn"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <Drawer anchor={"left"} open={isOpen} onClose={handleCloseMenu}>
        {list()}
      </Drawer>
    </React.Fragment>
  );
};

export default TheMenu;
