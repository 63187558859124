import { createSlice } from "@reduxjs/toolkit";

const thisSlice = createSlice({
  name: "customizationMenu",
  initialState: {
    isOpen: "dashboard",
    navType: "",
  },
  reducers: {
    showHideMenu: (state, action) => {
      return { ...state, isOpen: action.payload };
    },
  },
});

export const { showHideMenu } = thisSlice.actions;
export default thisSlice.reducer;
