import { createSlice } from "@reduxjs/toolkit";

const thisSlice = createSlice({
  name: "globalLoading",
  initialState: {
    isOpen: false,
  },
  reducers: {
    openGlobalLoading: (state, action) => {
      return { isOpen: true };
    },
    closeGlobalLoading: (state, action) => {
      return { isOpen: false };
    },
  },
});

export const { openGlobalLoading, closeGlobalLoading } = thisSlice.actions;
export default thisSlice.reducer;
