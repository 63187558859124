import React from "react";
import { Typography, Button, Container, Grid } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const NotFoundPage = () => {
  const rootStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  const iconStyle = {
    fontSize: 100,
    color: "#f44336",
  };

  const contentStyle = {
    textAlign: "center",
    marginBottom: "1rem",
  };

  const buttonStyle = {
    textDecoration: "none",
  };

  return (
    <div style={rootStyle}>
      <Container maxWidth="sm">
        <Grid container direction="column" alignItems="center" spacing={4}>
          <Grid item>
            <ErrorOutlineIcon style={iconStyle} />
          </Grid>
          <Grid item style={contentStyle}>
            <Typography variant="h4" gutterBottom>
              Không tìm thấy trang
            </Typography>
            <Typography variant="body1" gutterBottom>
              Trang bạn đang tìm kiếm có thể đã bị xóa hoặc không tồn tại.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              href="/"
              style={buttonStyle}
            >
              Tới trang chủ
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default NotFoundPage;
