import axios from "axios";
import { apis } from "../constants";
import { refreshToken } from "../token";
import { updateListTaxiWorking } from "../redux/slice/listTaxiWorking";

const fetchListTaxiWorking = async (dispatch) => {
  const userLocal = JSON.parse(localStorage.getItem("@loginData"));
  if (userLocal !== null) {
    try {
      const response = await axios.get(
        apis.apiDashboard + "/list-taxi-working",
        {
          headers: {
            verify: userLocal.accessToken,
            role: 1,
          },
        }
      );

      dispatch(updateListTaxiWorking(response.data));
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await fetchListTaxiWorking(dispatch);
      }
    }
  }
};

const initData = async (dispatch) => {
  // try {
    // await fetchListTaxiWorking(dispatch);
    return "OK";
  // } catch (error) {
  //   return "ERROR";
  // }
};

export { fetchListTaxiWorking, initData };
