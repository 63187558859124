import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startDate: null,
  endDate: null,
  listOrder: [],
};

const thisSlice = createSlice({
  name: "searchListOrder",
  initialState,
  reducers: {
    updateSearchListOrder: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateSearchListOrder } = thisSlice.actions;
export default thisSlice.reducer;
