import React from "react";

import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const GlobalLoading = () => {
  const globalLoadingRedux = useSelector((state) => state.globalLoading);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={globalLoadingRedux.isOpen}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default GlobalLoading;
