import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { apis } from "../../constants";
import { useDispatch } from "react-redux";
import { updateAccount } from "../../redux/slice/account";
import { useNavigate } from "react-router-dom";
import { openGlobalAlert } from "../../redux/slice/globalAlert";
import { initiateSocket } from "../../socket/useWebSocket";

const theme = createTheme();

const PageSignIn = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const alertInputNotValid = (message) => {
    dispatch(
      openGlobalAlert({
        severity: "error",
        message,
      })
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get("email");
    const password = data.get("password");

    if (email !== "" && password !== "") {
      try {
        const model = { email, password };
        const response = await axios.post(
          apis.apiDashboard + "/sign-in",
          model
        );
        const { accessToken, refreshToken, user } = response.data;

        const tokenSaveed = JSON.stringify({ accessToken, refreshToken });
        localStorage.setItem("@loginData", tokenSaveed);

        initiateSocket(user.id, accessToken);

        dispatch(updateAccount({ ...user, isSignIn: true }));
        navigate("/", { replace: true });
      } catch (error) {
        alertInputNotValid(error.response.data);
      }
    } else {
      alertInputNotValid("Bạn vui lòng nhập email và mật khẩu để đăng nhập");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Đăng nhập
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mật khẩu"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Đăng nhập
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PageSignIn;
