import { createSlice } from "@reduxjs/toolkit";

const thisSlice = createSlice({
  name: "listTaxiWorking",
  initialState: [],
  reducers: {
    updateListTaxiWorking: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateListTaxiWorking } = thisSlice.actions;
export default thisSlice.reducer;
