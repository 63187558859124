import React, { useEffect, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { Badge, Box, IconButton, Tooltip, Typography } from "@mui/material";
import { MRT_Localization_VI } from "material-react-table/locales/vi";
import { SIZES, apis, variable } from "../../constants";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import { refreshToken } from "../../token";
import { useDispatch, useSelector } from "react-redux";
import { updateListOrderNew } from "../../redux/slice/listOrderNew";
import {
  formatDateTime,
  formatPhone,
  formatPrice,
} from "../../functions/baseFunction";
import { showModalDetailOrder } from "../../redux/slice/globalModalDetailOrder";
import {
  closeGlobalLoading,
  openGlobalLoading,
} from "../../redux/slice/globalLoading";
import CachedIcon from "@mui/icons-material/Cached";

import PendingActionsIcon from "@mui/icons-material/PendingActions";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import SwipeLeftAltIcon from "@mui/icons-material/SwipeLeftAlt";
import { openModalListTaxiWorking } from "../../redux/slice/globalModalListTaxiWorking";
import { MODAL_CONFIRM_KEY_RECALL_ORDER } from "../../pages/util/global/GlobalModalConfirm";
import { showModalConfirm } from "../../redux/slice/globalModalConfirm";
import { MyTimer, StatusOrder } from "../util/component";
import getDataByStatusOrder from "../../functions/statusOrder";

const PageDefault = () => {
  const dispatch = useDispatch();
  const listOrderNewRedux = useSelector((state) => state.listOrderNew);

  useEffect(() => {
    async function fetchData() {
      loadData();
    }
    fetchData();

    return () => {
      // disconnectSocket();
    };
  }, []);

  const loadData = () => {
    dispatch(openGlobalLoading());
    setTimeout(() => {
      fetchListOrderNew();
      dispatch(closeGlobalLoading());
    }, 1000);
  };

  const fetchListOrderNew = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    if (userLocal !== null) {
      try {
        const response = await axios.get(
          apis.apiDashboard + "/list-order-new",
          {
            headers: {
              verify: userLocal.accessToken,
            },
          }
        );

        dispatch(updateListOrderNew(response.data));
      } catch (error) {
        if (error.response?.status === 401) {
          await refreshToken(userLocal);
          await fetchListOrderNew();
        }
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at",
        header: "Thời gian đặt",
        size: 120,
        enableClickToCopy: true,
        Cell: ({ renderedCellValue }) =>
          `${formatDateTime(renderedCellValue, "HH:mm dd-MM-yyyy")}`,
      },
      {
        accessorKey: "name_user", //access nested data with dot notation
        header: "Tên liên hệ",
        size: 120,
        enableClickToCopy: true,
      },
      {
        accessorKey: "phone",
        header: "SĐT liên hệ",
        size: 120,
        enableClickToCopy: true,
        Cell: ({ renderedCellValue }) => `${formatPhone(renderedCellValue)}`,
      },
      {
        accessorKey: "address",
        header: "Địa chỉ",
        size: 200,
        enableClickToCopy: true,
      },
      {
        accessorKey: "total",
        header: "Giá xe tạm tính",
        size: 100,
        enableClickToCopy: true,
        Cell: ({ renderedCellValue }) => `${formatPrice(renderedCellValue)}đ`,
      },
      {
        accessorFn: (row) => {
          return {
            orderstatus_id: row.orderstatus_id,
            point_delivery_id: row.point_delivery_id,
            delivery: row.delivery,
            order_delivery: row.order_delivery,
            created_at: row.created_at,
            updated_at: row.updated_at,
          };
        },
        header: "Trạng thái",
        size: 100,
        Cell: ({ renderedCellValue }) => {
          const {
            orderstatus_id,
            point_delivery_id,
            delivery,
            order_delivery,
            created_at,
            updated_at,
          } = renderedCellValue;

          const {
            titleStatus,
            backgroundColor,
            Icon,
            badgeContentTimer,
            time_handle,
            delivery_name,
          } = getDataByStatusOrder({
            orderstatus_id,
            point_delivery_id,
            created_at,
            updated_at,
            delivery,
            order_delivery,
          });

          const Timer = () => {
            let minute = 0;
            let second = 0;

            const timeNow = new Date();
            const timeData = new Date(time_handle);

            const timeDifference = parseInt(
              (timeNow.getTime() - timeData.getTime()) / 1000
            );

            minute = parseInt(timeDifference / 60);
            second = timeDifference % 60;

            return (
              <MyTimer
                initialMinute={minute}
                initialSeconds={second}
                delivery_code={badgeContentTimer}
                delivery_name={delivery_name}
              />
            );
          };

          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box>
                <Timer />
                <StatusOrder
                  title={titleStatus}
                  Icon={Icon}
                  backgroundColor={backgroundColor}
                />
              </Box>
            </Box>
          );
        },
      },
    ],
    []
  );

  // return (
  //   <div
  //     style={{ backgroundColor: "red", height: "100%", width: "100%" }}
  //   ></div>
  // );

  const ListTotalEveryStatusOrder = () => {
    const OneElement = ({ Icon, color, title, count }) => {
      return (
        <Tooltip title={title}>
          <Badge badgeContent={count} color={color} sx={{ ml: 2 }}>
            <Icon color="action" />
          </Badge>
        </Tooltip>
      );
    };

    return (
      <Box sx={{ display: "flex" }}>
        <OneElement
          Icon={() => <PendingActionsIcon />}
          color={"secondary"}
          title={variable.listStatusOrder[1].title}
          count={
            listOrderNewRedux.filter(
              (el) => el.orderstatus_id === 1 && !el.point_delivery_id
            ).length + ''
          }
        />
        <OneElement
          Icon={() => <DepartureBoardIcon />}
          color={"success"}
          title={"Chờ tài nhận"}
          count={
            listOrderNewRedux.filter(
              (el) => el.orderstatus_id === 1 && el.point_delivery_id
            ).length + ''
          }
        />
        <OneElement
          Icon={() => <MinorCrashIcon />}
          color={"primary"}
          title={variable.listStatusOrder[2].title}
          count={
            listOrderNewRedux.filter((el) => el.orderstatus_id === 2).length + ''
          }
        />
      </Box>
    );
  };

  const t = MODAL_CONFIRM_KEY_RECALL_ORDER;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          pt: 1,
          pr: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: {
                xs: 16,
                sm: 16,
                md: 18,
                lg: 24,
                xl: 24,
              },
            }}
          >
            Đơn mới
          </Typography>
          <IconButton onClick={loadData}>
            <CachedIcon />
          </IconButton>
        </Box>
        <ListTotalEveryStatusOrder />
      </Box>

      <MaterialReactTable
        columns={columns}
        data={listOrderNewRedux}
        //   enableRowSelection //enable some features
        //   enableColumnOrdering
        enableGlobalFilter={true} //turn off a feature
        // custom tìm kiếm
        enableFilterMatchHighlighting={false}
        muiSearchTextFieldProps={{
          placeholder: "Tìm kiếm",
        }}
        // show/hide dấu 3 chấm ở tiêu đề mỗi cột
        enableColumnActions={false}
        // show/hide nút tuỳ chỉnh kích cỡ bảng
        enableDensityToggle={true}
        // show/hide nút fullscreen
        enableFullScreenToggle={true}
        // show/hide dàn nút ở trên
        enableTopToolbar={false}
        // resize column
        enableColumnResizing
        columnResizeMode="onChange"
        // đánh số dòng
        // enableRowNumbers
        rowNumberMode="original"
        // max height bảng
        muiTableContainerProps={{
          sx: {
            height: SIZES.height * 0.63,
          },
        }}
        // ngôn ngữ hoá
        localization={MRT_Localization_VI}
        initialState={{
          // density: "compact",
          // expanded: true, //expand all groups by default
          // grouping: ["state"], //an array of columns to group by by default (can be multiple)
          pagination: { pageIndex: 0, pageSize: 20 },
          // sorting: [{ id: "state", desc: false }], //sort by state by default
        }}
        // gắn cứng header
        enableStickyHeader
        // custom toolbar
        //   renderToolbarInternalActions={({ table }) => (
        //     <Box>
        //       {/* add custom button to print table  */}
        //       <IconButton
        //         onClick={() => {
        //           window.print();
        //         }}
        //       >
        //         <PrintIcon />
        //       </IconButton>
        //       <MRT_GlobalFilterTextField table={table} />
        //       <Box>
        //         <MRT_ToggleDensePaddingButton table={table} />
        //         <MRT_FullScreenToggleButton table={table} />
        //       </Box>
        //     </Box>
        //   )}
        // custom page
        muiTablePaginationProps={{
          labelRowsPerPage: "Số dòng mỗi trang",
          labelDisplayedRows: ({ from, to, count, page }) => {
            //   return `${from}–${to} của ${
            //     count !== -1 ? count : `more than ${to}`
            //   }`;
            return `Trang ${page + 1}`;
          },
        }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row, table }) => {
          const dataRow = row.original;
          return (
            <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
              <Tooltip title="Xem chi tiết">
                <IconButton
                  color="primary"
                  onClick={() => {
                    dispatch(showModalDetailOrder({ data: dataRow }));
                  }}
                >
                  <RemoveRedEyeIcon />
                </IconButton>
              </Tooltip>

              {dataRow.orderstatus_id === 1 && !dataRow.point_delivery_id ? (
                <Tooltip title="Chỉ định tài">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      dispatch(
                        openModalListTaxiWorking({ order_id: dataRow.id })
                      );
                    }}
                  >
                    <TouchAppIcon />
                  </IconButton>
                </Tooltip>
              ) : (dataRow.orderstatus_id === 1 && dataRow.point_delivery_id) ||
                dataRow.orderstatus_id === 2 ? (
                <Tooltip title="Thu hồi">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      dispatch(
                        showModalConfirm({
                          content: "Xác nhận thu hồi đơn ?",
                          isShowButtonCancel: true,
                          isShowButtonAccept: true,
                          titleAccept: "Đồng ý",
                          keyActionAccept: MODAL_CONFIRM_KEY_RECALL_ORDER,
                          data: { order_id: dataRow.id },
                        })
                      );
                    }}
                  >
                    <SwipeLeftAltIcon />
                  </IconButton>
                </Tooltip>
              ) : null}

              {/* <IconButton
              color="primary"
              onClick={() =>
                window.open(
                  `mailto:kevinvandy@mailinator.com?subject=Hello ${row.original.firstName}!`
                )
              }
            >
              <EmailIcon />
            </IconButton> */}
              {/* <IconButton
              color="secondary"
              onClick={() => {
                table.setEditingRow(row);
              }}
            >
              <EditIcon />
            </IconButton> */}
              {/* <IconButton
              color="error"
              onClick={() => {
                data.splice(row.index, 1); //assuming simple data table
                //   setData([...data]);
              }}
            >
              <DeleteIcon />
            </IconButton> */}
            </Box>
          );
        }}
      />
    </>
  );
};

export default PageDefault;
