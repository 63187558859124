// const LOCALHOST = "http://192.168.1.4:3000";
const LOCALHOST = "https://server.iqi.com.vn";

const apiUser = LOCALHOST + "/users";
const apiDashboard = LOCALHOST + "/dashboard-taxi";


const exportedObject = {
  LOCALHOST,
  apiUser,
  apiDashboard
};

export default exportedObject;
