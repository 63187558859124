import PageDefault from "./pages/default";
import PageSearch from "./pages/search";

const privateRoutes = [
  {
    path: "/home",
    exact: true,
    name: "Trang chủ",
    component: () => <PageDefault />,
  },
  {
    path: "/search",
    exact: true,
    name: "Tra cứu đơn",
    component: () => <PageSearch />,
  },
];

export default privateRoutes;
