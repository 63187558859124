import io from "socket.io-client";
import { apis } from "../constants";

export let socket;

export const initiateSocket = (user_id, accessToken) => {
  if (!socket?.connected) {
    socket = io.connect(apis.LOCALHOST + "/admin/taxi", {
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10,
      query: { user_id, token: accessToken },
    });
  }
  // if (socket && room) socket.emit('join', room);
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};
